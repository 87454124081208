import Button from 'components/button'
import Input from 'components/input'
import Select, { Options } from 'components/select'
import { FormikProps } from 'formik'
import { maintenanceOptions, yesNoOptions } from 'modules/siteSurvey/components/siteSurveyDropDown'
import { financeOptions } from 'modules/siteSurvey/components/SiteSurveyOptions'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    IAdditionalConfigurationData,
    ISiteSurveyPriceData,
    PricingQueries,
} from '../queries/pricing'
import { IPricingForm } from '../views/Pricing'

interface IGeneralPricing {
    formik: FormikProps<IPricingForm>
    initialSiteSurveyData: any
    setLoading: (value: boolean) => void
    refetchInitial: () => void
    refetchAdditionalData: () => void
    refetchPricingData: () => void
    refetchCalculateResidualValueBigTruck: () => void
    refetchCalculateResidualValue: () => void
    isAttachmentSelected?: boolean
}

const GeneralPricing = (props: IGeneralPricing) => {
    const { t } = useTranslation()
    const { configurationId } = useParams()
    const {
        formik,
        initialSiteSurveyData,
        setLoading,
        refetchInitial,
        refetchAdditionalData,
        refetchCalculateResidualValue,
        refetchPricingData,
        refetchCalculateResidualValueBigTruck,
        isAttachmentSelected,
    } = props

    const saveAdditionalConfigurationData = useMutation(
        ['saveAdditionalConfigurationData'],
        (params: IAdditionalConfigurationData) =>
            PricingQueries.createAdditionalConfigurationData(params),
        {
            onSuccess: async () => {
                setLoading(true)
                toast.success(t('message.save_additional_configuration_data_success'))
                formik.handleSubmit()
                await refetchInitial()
                await refetchCalculateResidualValue()
                await refetchCalculateResidualValueBigTruck()
                if (!formik.values.siteSurveyId) {
                    await refetchAdditionalData()
                }
                await refetchPricingData()
                setLoading(false)
            },
            onError: () => {
                toast.error(t('message.save_additional_configuration_data_failed'))
            },
        },
    )

    const updateSiteSurveyPrice = useMutation(
        ['updateSurveyPrice'],
        (formData: ISiteSurveyPriceData) =>
            PricingQueries.updateSiteSurveyPrice(formik.values.siteSurveyId, formData),
        {
            onSuccess: async () => {
                toast.success(t('message.update_site_survey_success'))
                setLoading(true)
                formik.handleSubmit()
                await refetchInitial()
                await refetchCalculateResidualValue()
                await refetchCalculateResidualValueBigTruck()
                if (!formik.values.siteSurveyId) {
                    await refetchAdditionalData()
                }
                await refetchPricingData()
                setLoading(false)
            },
            onError: (error: Error) => {
                toast.error(error.message || t('message.update_site_survey_fail'))
            },
            onSettled: () => {
                setLoading(false)
            },
        },
    )

    const showAllPriceSeparateOptions: Options[] = [
        {
            label: t('api.yes'),
            value: 'yes',
        },
        {
            label: t('api.no'),
            value: 'no',
        },
        {
            label: t('api.only_service_separated'),
            value: 'only service separated',
        },
        {
            label: t('api.only_insurance_separated'),
            value: 'only insurance separated',
        },
    ]

    return (
        <div className="flex flex-col w-full gap-2 px-4">
            <div className="flex justify-between gap-4">
                <div className="flex-1">
                    <Select
                        horizontal
                        label={t('fields.financing')}
                        value={formik.values.financing}
                        showPlaceHolder
                        placeHolderOption={t('fields.financing_placeholder')}
                        options={financeOptions}
                        onChange={e => formik.setFieldValue('financing', e.target.value)}
                        error={formik.touched.financing && formik.errors.financing}
                        name="financing"
                    />
                </div>
                <div className="flex-1">
                    <Select
                        horizontal
                        label={t('fields.show_all_prices_separate')}
                        value={formik.values.showAllPriceSeparate}
                        showPlaceHolder
                        placeHolderOption={`${t('fields.select')} ${t(
                            'fields.show_all_prices_separate',
                        )}`}
                        options={showAllPriceSeparateOptions}
                        onChange={e => formik.setFieldValue('showAllPriceSeparate', e.target.value)}
                        error={
                            formik.touched.showAllPriceSeparate &&
                            formik.errors.showAllPriceSeparate
                        }
                        name="showAllPriceSeparate"
                    />
                </div>
            </div>
            <div className="flex justify-between gap-4">
                <div className="flex-1">
                    <Select
                        horizontal
                        label={t('fields.maintenance')}
                        value={formik.values.maintenance}
                        options={maintenanceOptions}
                        placeHolderOption={`${t('fields.select')}  ${t('fields.maintenance')}`}
                        showPlaceHolder
                        onChange={e => {
                            formik.setFieldValue('maintenance', e.target.value)
                            if (
                                e.target.value === 'PREVENTIVE_MAINTAINENCE' ||
                                e.target.value === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE'
                            ) {
                                formik.setFieldValue('bmwt', true)
                                if (formik.values.isElectric) {
                                    formik.setFieldValue('bmwtBattery', true)
                                }
                            } else {
                                formik.setFieldValue('bmwt', false)
                                formik.setFieldValue('bmwtBattery', false)
                            }
                            if (
                                e.target.value === 'ALL_IN' ||
                                e.target.value === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE'
                            ) {
                                formik.setFieldValue('addBattery', true)
                                if (isAttachmentSelected) {
                                    formik.setFieldValue('addAttachment', true)
                                }
                            } else {
                                formik.setFieldValue('addBattery', false)
                                formik.setFieldValue('addAttachment', false)
                            }
                            if (formik.values.siteSurveyId) {
                                updateSiteSurveyPrice.mutate({
                                    financing: formik.values.financing,
                                    insurance: formik.values.insurance,
                                    maintenance: e.target.value,
                                    show_all_prices_separate: formik.values.showAllPriceSeparate,
                                    return: formik.values.return,
                                    running_hour: formik.values.runningHours,
                                    replacement_machine: formik.values.returnDescription,
                                })
                            } else {
                                saveAdditionalConfigurationData.mutate({
                                    configuration_id: parseInt(String(configurationId || '0'), 10),
                                    financing: formik.values.financing,
                                    insurance: formik.values.insurance,
                                    maintenance: e.target.value,
                                    return: formik.values.return,
                                    return_description: formik.values.returnDescription,
                                    running_hours: formik.values.runningHours,
                                    show_all_prices_separate: formik.values.showAllPriceSeparate,
                                    theoretical_category: String(formik.values.category),
                                })
                            }
                        }}
                        error={formik.touched.maintenance && formik.errors.maintenance}
                        name="maintenance"
                    />
                </div>
                <div className="flex-1">
                    <Select
                        horizontal
                        label={t('fields.return')}
                        value={formik.values.return ? 'yes' : 'no'}
                        options={yesNoOptions}
                        placeHolderOption={`${t('fields.select')} ${t('fields.return')}`}
                        showPlaceHolder
                        onChange={e => formik.setFieldValue('return', e.target.value === 'yes')}
                        error={formik.touched.maintenance && formik.errors.return}
                        name="return"
                    />
                </div>
            </div>
            <div className="flex justify-between gap-4">
                <div className="flex-1">
                    <Select
                        horizontal
                        label={t('fields.insurance')}
                        value={formik.values.insurance ? 'yes' : 'no'}
                        options={yesNoOptions}
                        placeHolderOption={`${t('fields.select')} ${t('fields.insurance')}`}
                        showPlaceHolder
                        onChange={e => {
                            formik.setFieldValue('insurance', e.target.value === 'yes')
                            if (formik.values.siteSurveyId) {
                                updateSiteSurveyPrice.mutate({
                                    financing: formik.values.financing,
                                    insurance: e.target.value === 'yes',
                                    maintenance: formik.values.maintenance,
                                    show_all_prices_separate: formik.values.showAllPriceSeparate,
                                    return: formik.values.return,
                                    running_hour: formik.values.runningHours,
                                    replacement_machine: formik.values.returnDescription,
                                })
                            } else {
                                saveAdditionalConfigurationData.mutate({
                                    configuration_id: parseInt(String(configurationId || '0'), 10),
                                    financing: formik.values.financing,
                                    insurance: e.target.value === 'yes',
                                    maintenance: formik.values.maintenance,
                                    return: formik.values.return,
                                    return_description: formik.values.returnDescription,
                                    running_hours: formik.values.runningHours,
                                    show_all_prices_separate: formik.values.showAllPriceSeparate,
                                    theoretical_category: String(formik.values.category),
                                })
                            }
                        }}
                        error={formik.touched.insurance && formik.errors.insurance}
                        name="insurance"
                    />
                </div>
                <div className="flex-1">
                    {formik.values.return && (
                        <Input
                            horizontal
                            label={t('fields.manual_enter')}
                            placeholder={t('fields.manual_enter')}
                            value={formik.values.returnDescription}
                            onChange={e =>
                                formik.setFieldValue('returnDescription', e.target.value)
                            }
                            error={
                                formik.touched.returnDescription && formik.errors.returnDescription
                            }
                            name="returnDescription"
                        />
                    )}
                </div>
            </div>
            <div className="flex justify-between gap-4">
                <div className="flex-1">
                    <Input
                        horizontal
                        label={t('fields.running_hours')}
                        name="runningHours"
                        type="number"
                        customInputStyles="p-0 text-right"
                        value={formik.values.runningHours}
                        onChange={e =>
                            formik.setFieldValue('runningHours', parseFloat(e.target.value))
                        }
                        rightContent={<p>hrs</p>}
                    />
                </div>
                <div className="flex-1" />
            </div>
            {initialSiteSurveyData &&
                (initialSiteSurveyData.financing !== formik.values.financing ||
                    initialSiteSurveyData.insurance !== formik.values.insurance ||
                    initialSiteSurveyData.maintenance !== formik.values.maintenance ||
                    initialSiteSurveyData.running_hours !== formik.values.runningHours ||
                    initialSiteSurveyData.returnDescription !== formik.values.returnDescription ||
                    initialSiteSurveyData.return !== formik.values.return) && (
                    <div className="flex justify-end gap-4 w-full">
                        <div className="my-2">
                            <Button
                                label={t('api.update_site_survey')}
                                variant="primary"
                                isLoading={
                                    updateSiteSurveyPrice.isLoading ||
                                    saveAdditionalConfigurationData.isLoading
                                }
                                onClick={() => {
                                    updateSiteSurveyPrice.mutate({
                                        financing: formik.values.financing,
                                        insurance: formik.values.insurance,
                                        maintenance: formik.values.maintenance,
                                        return: formik.values.return,
                                        show_all_prices_separate:
                                            formik.values.showAllPriceSeparate,
                                        running_hour: formik.values.runningHours,
                                        replacement_machine: formik.values.returnDescription,
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
            {!initialSiteSurveyData && (
                <div className="flex justify-end gap-4 w-full">
                    <div className="my-2">
                        <Button
                            label={
                                formik.values.siteSurveyId
                                    ? t('api.update_site_survey')
                                    : t('api.update')
                            }
                            variant="primary"
                            isLoading={
                                updateSiteSurveyPrice.isLoading ||
                                saveAdditionalConfigurationData.isLoading
                            }
                            onClick={() => {
                                if (formik.values.siteSurveyId) {
                                    updateSiteSurveyPrice.mutate({
                                        financing: formik.values.financing,
                                        insurance: formik.values.insurance,
                                        maintenance: formik.values.maintenance,
                                        show_all_prices_separate:
                                            formik.values.showAllPriceSeparate,
                                        return: formik.values.return,
                                        running_hour: formik.values.runningHours,
                                        replacement_machine: formik.values.returnDescription,
                                    })
                                } else {
                                    saveAdditionalConfigurationData.mutate({
                                        configuration_id: parseInt(
                                            String(configurationId || '0'),
                                            10,
                                        ),
                                        financing: formik.values.financing,
                                        insurance: formik.values.insurance,
                                        maintenance: formik.values.maintenance,
                                        return: formik.values.return,
                                        return_description: formik.values.returnDescription,
                                        running_hours: formik.values.runningHours,
                                        show_all_prices_separate:
                                            formik.values.showAllPriceSeparate,
                                        theoretical_category: String(formik.values.category),
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default GeneralPricing
